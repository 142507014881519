import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";


@Injectable()

export class RelatorioService{

    constructor(private http: HttpClient){}

    obterformandosConcluidos(empresaId, cursoId): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/relatorios/formandos/concluidos`, {empresaId, cursoId})
        .pipe(map((result: any) => result))
    }
    
    obterformandosAndamento(empresaId, cursoId): Observable<any>{
      return this.http.post<any>(`${BACKEND_API}/relatorios/formandos/emandamento`, {empresaId, cursoId})
      .pipe(map((result: any) => result))
    }

    obterformandosSemCadastro(empresaId, cursoId): Observable<any>{
      return this.http.post<any>(`${BACKEND_API}/relatorios/formandos/semcadastro`, {empresaId, cursoId})
      .pipe(map((result: any) => result))
    }

    obterformandosatividade(turma): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/relatorios/formandos/sumarioTempo/${turma}`)
        .pipe(map((result: any) => result))
      }

      obterformandosatividadeStatus(turma): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/relatorios/formandos/sumarioTempoStatus/${turma}`)
        .pipe(map((result: any) => result))
      }
    
      obterAcaoEmpresa(empresa): Observable<any>{
        return this.http.get<any>(`${BACKEND_API}/relatorios/formandos/acaoPorEmpresa/${empresa}`)
        .pipe(map((result: any) => result))
      }

}