import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Curso, Categoria, Subcategoria, Concurso, Modulo, Capitulo } from "./curso.model";

@Injectable()

export class CursoService{

    conteudoIframe: string

    constructor(private http: HttpClient){}
    
    createCapitulo(capitulo: Capitulo): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/capitulos`, capitulo)
        .pipe(map((result: any) => result))
    }
    
    createCapituloConteudo(capituloConteudo: any): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/capituloconteudo`, capituloConteudo)
        .pipe(map((result: any) => result))
    }

    createCategoria(categoria: Categoria): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/categorias`, categoria)
        .pipe(map((result: any) => result))
    }
    
    createConcurso(concurso: Concurso): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/concursos`, concurso)
        .pipe(map((result: any) => result))
    }

    createConcursoCurso(idCurso: number, ids: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/cursos/concursocurso/${idCurso}`, ids)
        .pipe(map((result: any) => result))
    }

    createCurso(curso: Curso): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/cursos`, curso)
        .pipe(map((result: any) => result))
    }   
    
    obterCursoFoto(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/cursofoto`)
    }

    obterPerguntaFoto(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/pergunta`)
    }

    createCursoModulo(idCurso: number, ids: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/cursos/cursomodulos/${idCurso}`, ids)
        .pipe(map((result: any) => result))
    }

    createCursoConcurso(idConcurso: number, ids: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/cursos/cursoconcurso/${idConcurso}`, ids)
        .pipe(map((result: any) => result))
    }

    createVideo(dados: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/upload/createvideo`, dados)
        .pipe(map((result: any) => result))
    }

    deleteArquivo(id: number, tipo:string): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/capituloconteudo/${id}/${tipo}`)
        .pipe(map((result: any) => result))
    }

    deleteCategoria(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/categorias/${id}`)
        .pipe(map((result: any) => result))
    }

    deleteCapitulo(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/capitulos/${id}`)
        .pipe(map((result: any) => result))
    }

    deleteConcurso(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/concursos/${id}`)
        .pipe(map((result: any) => result))
    }

    deleteCurso(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/cursos/${id}`)
        .pipe(map((result: any) => result))
    }
    
    deleteModulo(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/modulos/${id}`)
        .pipe(map((result: any) => result))
    }

    obterApostilas(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/listaapostila`)
    }

    obterCapituloApostilas(idCapitulo:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/capitulos/apostilas/${idCapitulo}`)
    }
    
    obterCapituloSemobterCursoModulo(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/modulos/modulosemcapitulo/${id}/${sem}`)
    }

    obterCapituloVideos(idCapitulo:number): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/capitulos/videos/${idCapitulo}`)
    }

    obterCapituloVideosCombo(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/capitulos/videoscombo`)
    }

    obterCursoSemConcurso(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/concursos/cursosemconcurso/${id}/${sem}`)
    } 
    
    obterCursoConcurso(id: number, com: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/concursos/cursoconcurso/${id}/${com}`)
    } 

    obterCursoModulo(id: number, com: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/cursomodulo/${id}/${com}`)
    } 

    obterCursoSemModulo(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/cursosemmodulo/${id}/${sem}`)
    } 

    //Obter as perguntas de um modulo
    obterCursoModuloPergunta(id: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/questions/modulo/${id}`)
    } 

    obterModuloFoto(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/modulofoto`)
    }

    obterModuloSemCurso(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/modulosemcurso/${id}/${sem}`)
    } 

    createSubcategoria(subcategoria: Subcategoria): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/subcategorias`, subcategoria)
        .pipe(map((result: any) => result))
    }

    createModulo(modulo: Modulo): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/modulos`, modulo)
        .pipe(map((result: any) => result))
    }

    createModuloCapitulo(idModulo: number, ids: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/modulos/modulocapitulos/${idModulo}`, ids)
        .pipe(map((result: any) => result))
    }

    createModuloCurso(idModulo: number, ids: any[]): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/modulos/modulocursos/${idModulo}`, ids)
        .pipe(map((result: any) => result))
    }

    obterCapitulosGrid(): Observable<Capitulo[]> {
        return this.http.get<Capitulo[]>(`${BACKEND_API}/capitulos/grid`)
    }
    
    obterCapituloId(id : number): Observable<Capitulo> {
        return this.http.get<Capitulo>(`${BACKEND_API}/capitulos/id/${id}`)
    } 

    obterCategoriaId(id : number): Observable<Categoria> {
        return this.http.get<Categoria>(`${BACKEND_API}/categorias/id/${id}`)
    } 

    obterCursos(): Observable<Curso[]> {
        return this.http.get<Curso[]>(`${BACKEND_API}/cursos`)
    }  
           
    obterCursoId(id : number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/id/${id}`)
    } 

    obterCursosGrid(): Observable<Curso[]> {
        return this.http.get<Curso[]>(`${BACKEND_API}/cursos/grid`)
    } 

    obterCategorias(): Observable<Categoria[]> {
        return this.http.get<Categoria[]>(`${BACKEND_API}/categorias`)
    }  

    obterCategoriasCombo(): Observable<Categoria[]> {
        return this.http.get<Categoria[]>(`${BACKEND_API}/categorias/combo`)
    }  

    obterCategoriasGrid(): Observable<Curso[]> {
        return this.http.get<Curso[]>(`${BACKEND_API}/categorias/grid`)
    } 
        
    obterConcursoCurso(id: number, com: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/concursocurso/${id}/${com}`)
    } 

    obterConcursos(): Observable<Concurso[]> {
        return this.http.get<Concurso[]>(`${BACKEND_API}/concursos`)
    }

    obterConcursosSemCurso(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/concursos/concursosemcurso/${id}/${sem}`)
    } 

    obterConcursosGrid(): Observable<Concurso[]> {
        return this.http.get<Concurso[]>(`${BACKEND_API}/concursos/grid`)
    }

    obterConcursoId(id : number): Observable<Concurso> {
        return this.http.get<Concurso>(`${BACKEND_API}/concursos/id/${id}`)
    } 

    obterModuloCurso(id: number, com: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/modulocurso/${id}/${com}`)
    } 

    getCursoModulo(id: number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/cursos/modulos/${id}`)
    } 

    obterModulosGrid(): Observable<Modulo[]> {
        return this.http.get<Modulo[]>(`${BACKEND_API}/modulos/grid`)
    } 

    obterModuloId(id : number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/modulos/id/${id}`)
    } 

    obterModuloCapitulo(id: number, com: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/modulos/modulocomcapitulo/${id}/${com}`)
    } 

    obterModuloSemCapitulo(id: number, sem: any): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/modulos/modulosemcapitulo/${id}/${sem}`)
    } 

    obterSubcategorias(): Observable<Subcategoria[]> {
        return this.http.get<Subcategoria[]>(`${BACKEND_API}/subcategorias`)
    } 
    
    obterSubcategoriasGrid(): Observable<Subcategoria[]> {
        return this.http.get<Subcategoria[]>(`${BACKEND_API}/subcategorias/grid`)
    } 
    
    obterSubategoriaId(id : number): Observable<Subcategoria> {
        return this.http.get<Subcategoria>(`${BACKEND_API}/subcategorias/id/${id}`)
    } 

    obterVideos(id : number): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/videos/${id}`)
    } 

    updateCapitulo(capitulo: Capitulo): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/capitulos`, capitulo)
        .pipe(map((result: any) => result))
    }

    updateCategoria(categoria: Categoria): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/categorias`, categoria)
        .pipe(map((result: any) => result))
    }
    
    updateConcurso(concurso: Concurso): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/concursos`, concurso)
        .pipe(map((result: any) => result))
    }

    updateCurso(curso: Curso): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/cursos/${curso.id}`, curso)
        .pipe(map((result: any) => result))
    }

    updateModulo(modulo: Modulo): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/modulos`, modulo)
        .pipe(map((result: any) => result))
    }

    updateSubcategoria(subcategoria: Subcategoria): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/subcategorias`, subcategoria)
        .pipe(map((result: any) => result))
    }

    uploadApostila(arquivo: File, nome, idProfessor, capitulo, link ): Observable<any>{
        let form = new FormData()
        // let fileList: FileList = arquivo.files

        // if(fileList.length > 0) { 
        //     let file_: File = fileList[0]

            form.append('file', arquivo)
            form.append('nome', nome)
            form.append('idCapitulo', capitulo)
            form.append('idProfessor', idProfessor)
            form.append('link', link)
            form.append('tipo', 'A')
        // }

        return this.http.post<any>(`${BACKEND_API}/files/uploadapostila`, form)
        .pipe(map((result: any) => result))
    }
    
    uploadCursoFoto(file: any, id:number): Observable<any>{

        let formFoto = new FormData()

        formFoto.append('file', file)
        formFoto.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }

        return this.http.post<any>(`${BACKEND_API}/files/uploadcursofoto`, formFoto)
        .pipe(map((result: any) => result))
    }

    uploadPerguntaFoto(file: any, id:number): Observable<any>{

        let formFoto = new FormData()

        formFoto.append('file', file)
        formFoto.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }

        return this.http.post<any>(`${BACKEND_API}/files/pergunta`, formFoto)
        .pipe(map((result: any) => result))
    }
    
    uploadModuloFoto(file: any, id:number): Observable<any>{
        console.log(file)
        let formFoto = new FormData()

        formFoto.append('file', file)
        formFoto.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }

        return this.http.post<any>(`${BACKEND_API}/files/uploadmodulofoto`, formFoto)
        .pipe(map((result: any) => result))
    }

    uploadVimeo(file, title, tipo, capitulo, idProfessor, duracao ): Observable<any>{
        let form = new FormData()
        
        let fileList: FileList = file.files
        
        if(fileList.length > 0) {
            let file_: File = fileList[0]
            form.append('file', file_)
        }
        
        form.append('title', title)
        form.append('capituloId', capitulo)
        form.append('type', tipo)
        form.append('idProfessor', idProfessor)
        form.append('duracao', duracao)
                    
        return this.http.post<any>(`${BACKEND_API}/upload/vimeo`, form)
        .pipe(map((result: any) => result))
    }

    updateSortModulo(data): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/modulos/modulocursos/save/position`, data)
    }

    updateSortConteudo(data): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/modulos/modulocapitulo/save/position`, data)
    }

  
    

}