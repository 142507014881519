import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Empresa } from "./empresa.model";

@Injectable()

export class EmpresaService{

    constructor(private http: HttpClient){}
    
    createEmpresa(dados: any, image: any): Observable<any>{
        let form = new FormData()

        if(image != undefined){
            form.append('file', image.files[0], image.files[0].name)
        }
        form.append('nome', dados.nome)
        form.append('nif', dados.nif)

        const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
        })
        }

        return this.http.post<any>(`${BACKEND_API}/empresas`, form)
        .pipe(map((result: any) => result))
    }

    deleteEmpresa(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/empresas/${id}`)
        .pipe(map((result: any) => result))
    }

    obterEmpresas(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/empresas`)
    }

    obterEmpresasId(id): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/empresas/${id}`)
    }

    updateEmpresa(empresa: any, image: any): Observable<any>{

        let form = new FormData()
        form.append('file', image.files[0], image.files[0].name)
        form.append('nome', empresa.nome)
        form.append('nif', empresa.nif)
        form.append('id', empresa.id)

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'false',
                "processData": 'false'
            })
            }

        return this.http.put<any>(`${BACKEND_API}/empresas/${empresa.id}`, form)
        .pipe(map((result: any) => result))
    }

    obterCursoEmpresas(empresaId): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}//curso/empresas/${empresaId}`)
    }

}