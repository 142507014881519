import { Component, OnInit } from '@angular/core';
import { LoginService } from '../security/login/login.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  loggedIn: boolean
  constructor( private loginService: LoginService, route: Router) { 
    this.loggedIn = this.isloggedin()
  }

  ngOnInit() {
    
  }
  isloggedin(){
    return this.loginService.isLoggedIn()
  }

  getRole(){
    // console.log(this.loginService.getRole())
    return this.loginService.getRole()

  }



  logout() {

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente sair do sistema?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(willDelete => {
      if (willDelete.value) {
        this.loginService.logout()
        return this.loginService.usuario()
      }
    });
  }  

}
