import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, FormControl } from '@angular/forms';
import swal from 'sweetalert2';
import { Acao } from '../acao.model';
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { CursoService } from 'src/app/curso/curso.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AcaoService } from '../acao.service';
import { ReplaySubject, Subject } from 'rxjs';
import { ProfessorService } from 'src/app/professor/professor.service';


@Component({
  selector: 'app-formacao',
  templateUrl: './formacao.component.html',
  styleUrls: ['./formacao.component.scss']
})
export class FormacaoComponent implements OnInit {


  @ViewChild(FormGroupDirective) cadastroForm: FormGroup

  empresas: any
  cursos: any
  dataInicial: any
  dataFinal: any
  formadoresList: any[] = []
  empresaLista: any[] = []
  acaoId : any
  typeForm : any
  public filteredFormadores: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)
  public formadorSelected: FormControl = new FormControl();

  // cadastroForm: FormGroup

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder, 
    private empresaService: EmpresaService,
    private acaoService: AcaoService,
    private cursoService: CursoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private professorService: ProfessorService) { 
      this.acaoId = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']
      this.typeForm = this.activatedRoute.snapshot.params['type']==undefined ? undefined : this.activatedRoute.snapshot.params['type']

    }

  ngOnInit() {

            
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.filteredFormadores.next(this.formadoresList.slice())

    this.cadastroForm = this.fb.group({
      acao: this.fb.control('', [Validators.required]),
      ano: this.fb.control(''),
      empresaId: this.fb.control(''),
      cursoId: this.fb.control(''),
      usuarioId: this.fb.control(''),
      dataInicio: this.fb.control(''),
      dataFim: this.fb.control(''),
      turma: this.fb.control(''),
      formadores: this.fb.control(''),
      empresas: this.fb.control('')
    })

    if(this.acaoId == 'undefined'){
      this.getEmpresa()
      this.getCurso()
      this.getProfessor()
    }else{

      this.getEmpresa()
      this.getCurso()
      this.getProfessor()
      this.load(this.acaoId)

    }
    
    swal.close()

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getEmpresa(){

    this.empresaService.obterEmpresas().subscribe(result =>{
      this.empresas = result
    })

  }

  getCurso(){
    this.cursoService.obterCursos().subscribe(result =>{
      this.cursos = result
    })
  }

  getProfessor(){
    this.professorService.obterFormadores().subscribe(result =>{
      this.formadoresList = result
    })
  }

  

  setDataIni(data: any){

    if(data.value==undefined || data.value==null){
      this.dataInicial = ''
    }else{
      let dataN = data.value.toString()
      //dataN = moment(dataN).format("YYYY") + "-" + moment(dataN).format("DD") + "-" + moment(dataN).format("MM")
      this.dataInicial = moment(dataN).format("DD-MM-YYYY")//moment(dataN).add(1,'days').format('YYYY-MM-DD')
    }

    console.log("dataIni", this.dataInicial)

  }

  setDataFinal(data: any){

    if(data.value==undefined || data.value==null){
      this.dataFinal = ''
    }else{
      let dataN = data.value.toString()
      //dataN = moment(dataN).format("YYYY") + "-" + moment(dataN).format("DD") + "-" + moment(dataN).format("MM")
      this.dataFinal =  moment(dataN).format("DD-MM-YYYY") //moment(dataN).add(1,'days').format('YYYY-MM-DD')
    }

    console.log("dataFim", this.dataFinal)

  }

  update(acao:any) {
    let dados = acao
    dados.id = this.activatedRoute.snapshot.params['id']
    this.acaoService.updateAcao(dados).subscribe(result=>{
      
          swal({
            title: 'Potencial Global',
            text: 'Empresa alterado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          setTimeout( () => {
              this.router.navigate(['/dashboard/acao'])
            }, 2000 );
        
      },(error: HttpErrorResponse)=>{})
  }

  save(acao : Acao){

    // acao.dataInicio = this.dataInicial
    // acao.dataFim = this.dataFinal
    this.acaoService.createAcao(acao).subscribe(result=>{
        swal({
          title: 'Potencial Gobal',
          text: 'Ação cadastrado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        setTimeout( () => {
          this.router.navigate(['/dashboard/acao'])
        }, 2000 );
    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de ação.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  gravar(acao : Acao){
    if(this.typeForm == undefined){
      this.save(acao)
    }else{
      this.update(acao)
    }
  }

  private filterformadoresMulti() {
    if (!this.formadoresList) {
      return;
    }
    // get the search keyword
    let search = this.formadorSelected.value;
    if (!search) {
      this.filteredFormadores.next(this.formadoresList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredFormadores.next(
      this.formadoresList.filter(formador => formador.name.toLowerCase().indexOf(search) > -1)
    );
  }

  load(id: any){
    this.acaoService.obterAcaoId(id).subscribe(result =>{
      let formadoresArray = []
      let empresasArray = []
      formadoresArray = result.acaoFormadores.map(item =>{return item.id })
      empresasArray = result.empresas.map(item =>{return item.id })
      this.dataFinal = result.dataFim
      this.dataInicial =  result.dataInicio
      this.cadastroForm.patchValue({
        acao: result.acao,
        ano: result.ano,
        empresaId: result.escolaId,
        cursoId: result.cursoId,
        usuarioId: result.usuarioId,
        turma: result.turma,
        formadores: formadoresArray,
        empresas: empresasArray,
        dataFim: this.dataFinal,
        dataInicio: this.dataInicial
      })
      
    })
   
  }

}
