import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { BACKEND_API, BACKEND_FILE } from '../app.api';
import { Observable } from "rxjs";


@Injectable()

export class ForumService{

  constructor(private http: HttpClient){}
  
  createDiscussion(data): Observable<any> {
    return this.http.post<any>(`${BACKEND_API}/forum/discussion/create/admin`, data)
  }
  
  createComments(data): Observable<any> {
    return this.http.post<any>(`${BACKEND_API}/forum/comments/${data.discussionId}`, data)
  }

  getAllDiscussion(): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/forum/discussions/admin`)
  }

  getAllComments(discussionId): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/forum/allComments/${discussionId}`)
  }

  getOneDiscussion(id): Observable<any> {
    return this.http.get<any>(`${BACKEND_API}/forum/discussions/one/${id}`)
  }

}  