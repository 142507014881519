import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import { Usuario } from "./usuario.model"
import { BACKEND_API } from './../../app.api'
import { NavigationEnd, Router } from "@angular/router";

@Injectable()
export class LoginService {

  //usuario: Usuario
  lastUrl: string
  private roleId : number

  constructor(private http: HttpClient, private router: Router) {
    this.router.events
        .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.lastUrl = e.url)
  }

  //Identificar se o usuario esta logado
  isLoggedIn(): boolean {    
    let usuario: Usuario = localStorage.getItem("progressao")?JSON.parse(localStorage.getItem("progressao")):undefined
    return usuario !== undefined
  }

  usuario(): Usuario {
    return localStorage.getItem("progressao")?JSON.parse(localStorage.getItem("progressao")):''
  }

  login(usuario: Usuario): Observable<Usuario> {

    return this.http.post<Usuario>(`${BACKEND_API}O/auth/login`, usuario)

  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['/login', btoa(path)])
  }

  logout() {
    this.router.navigate(['/login'])
    localStorage.removeItem("progressao")
  }

  requestNewPassword(email: string) {
    return this.http.post(`${BACKEND_API}O/sendmail/recuperarsenha`,{'email':email})
  }

  getRoleService(): Observable<any> {
      return this.http.get<any>(`${BACKEND_API}/usuarios/role/`)
  } 

  setRole(role){
    console.log("role =>"  + role)
      this.roleId = role
  }

  getRole(){
    // console.log(this.roleId)
      return !this.roleId ? 0 : this.roleId
  }
}