import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { RelatorioService } from '../relatorio.service';
import swal from 'sweetalert2';
import { RelatorioUtil } from 'src/app/shared/util/export-excel.util';

@Component({
  selector: 'app-formandos-curso-emandamento',
  templateUrl: './formandos-curso-emandamento.component.html',
  styleUrls: ['./formandos-curso-emandamento.component.scss']
})
export class FormandosCursoEmandamentoComponent implements OnInit {

  displayedColumns = ['nome', 'nif']
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private empresaService: EmpresaService,
            private relatorioService: RelatorioService) { }



  ngOnInit() {
    this.obtEprempresas()
  }

  empresas: any
  empresaId: any
  cursoId: any
  cursos: any

  obtEprempresas(){
    this.empresaService.obterEmpresas()
          .subscribe(retorno => {
          this.empresas = retorno
        })
  }


  retornaCursos(){
    this.empresaService.obterCursoEmpresas(this.empresaId).subscribe(result=>{
      this.cursos = result
    }, (error)=>{
      console.log(error)
    })
  }

  retornaFormandosConcluidos(){
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();
    this.relatorioService.obterformandosAndamento(this.empresaId, this.cursoId).subscribe(result=>{
      this.dataSource = new MatTableDataSource(result.formandos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    }, (erro)=>{
      
      swal.close()
    })
  }

  exportTable(){
    RelatorioUtil.exportToExcel("tableExporter");
  }

}
