import {Component, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

import {LoginService} from './login.service'
import {Usuario} from './usuario.model'
import {ActivatedRoute, Router} from "@angular/router";
import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  preserveWhitespaces: true
})
export class LoginComponent implements OnInit {

  usuario: Usuario
  loginForm: FormGroup
  redirectTo: string
  validacao: boolean = false
  hide: boolean = true
  constructor(public loginService: LoginService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    swal.close()
    this.loginForm = this.fb.group({
      username: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
      conected: this.fb.control(''),
    })
    this.redirectTo = this.activatedRoute.snapshot.params['to'] || btoa('dashboard/home')
  }

  login(usuario: Usuario) {

    this.loginService.login(usuario).subscribe(async retorno => {
      if(retorno["errors"] != undefined){
        this.validacao = true
      } else {
        this.usuario = retorno
        localStorage.setItem('progressao', JSON.stringify(retorno))
        this.router.navigate(['dashboard'])
       
      }
    },(error)=>{ 
      this.validacao = true    
    })
  }
}