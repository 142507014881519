import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { AcaoService } from 'src/app/acao/acao.service';
import { CursoService } from 'src/app/curso/curso.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfessorService } from 'src/app/professor/professor.service';
import { ForumService } from '../forum.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-formforum',
  templateUrl: './formforum.component.html',
  styleUrls: ['./formforum.component.scss']
})
export class FormforumComponent implements OnInit {


  @ViewChild(FormGroupDirective) cadastroForm: FormGroup

  empresas: any
  cursos: any
  dataInicial: any
  dataFinal: any
  formadoresList: any[] = []
  acaoId : any
  typeForm : any
  descricao: string 
  acoes: any

  @ViewChild("ckeDescricao") ckeditor: any;
  public filteredFormadores: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)
  public formadorSelected: FormControl = new FormControl();

  constructor(
    private fb: FormBuilder, 
    private empresaService: EmpresaService,
    private acaoService: AcaoService,
    private cursoService: CursoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private professorService: ProfessorService,
    private forumService: ForumService

  ) { }

  ngOnInit() {

    this.cadastroForm = this.fb.group({
      fixo: this.fb.control(''),
      subject: this.fb.control('',  [Validators.required]),
      descricao: this.fb.control(''),
      turmaId: this.fb.control('')        
    })

    this.loadAcao()
  }

  gravar(forum : any){
    let data : any
    data = forum
    data.text = this.descricao
    this.forumService.createDiscussion(data).subscribe(result=>{
      swal({
        title: 'Potencial Gobal',
        text: 'Topico cadastrado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      this.router.navigate(['/dashboard/forum'])
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro topico no forum.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })

    })
    console.log(data)
  }

  public onChange( event ) {
    this.descricao = event
  }

  loadAcao(){
    this.acaoService.obterAcao().subscribe(acoes =>{
      this.acoes = acoes
    })
  }

}
