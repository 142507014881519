import { Component, OnInit, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Empresa } from './empresa.model';
import { EmpresaService } from './empresa.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  displayedColumns = ['nome',  'acao']
  dataSource: MatTableDataSource<Empresa>
  empresaId: any
  

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  
  constructor(public empresaService : EmpresaService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.load()
  }
  


  load(){

    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.empresaService.obterEmpresas()
    .subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()})
  }

  delete(id){

    swal({
      title: 'Potencial Global',
      text: 'Deseja realmente excluir a empresa?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar"
    })
    .then(retorno => {
      if (retorno.value) {    
        this.empresaService.deleteEmpresa(id).subscribe(res=>{
          this.load()
        })
      }
    })

  }
  update(id: number){
    this.router.navigate(['dashboard/empresas/form',id]);
  }

}
