import { Component, OnInit, ViewChild } from '@angular/core';
import { ForumService } from '../forum.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {

  displayedColumns = ['status', 'turma', 'pergunta', 'autor', 'respostas', 'acao']
  dataSource: MatTableDataSource<any>
  empresaId: any
  
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  constructor(private forumService: ForumService) { }

  ngOnInit() {
    this.load()
  }


  load(){
    this.forumService.getAllDiscussion().subscribe(result =>{
      this.dataSource =  new MatTableDataSource(result); 
    })
  }

}
