import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Professor } from "./professor.model";

@Injectable()

export class ProfessorService{

    constructor(private http: HttpClient){}
    
    create(professor: Professor): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/professores`, professor)
        .pipe(map((result: any) => result))
    }

    delete(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/professores/${id}`)
        .pipe(map((result: any) => result))
    }

    obterProfessorFoto(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/files/professorfoto`)
    }

    obterProfessorId(id : number): Observable<Professor> {
        return this.http.get<Professor>(`${BACKEND_API}/professores/id/${id}`)
    }  

    obterProfessoresCombo(): Observable<Professor[]> {
        return this.http.get<Professor[]>(`${BACKEND_API}/professores/obterprofessorescombo`)
    } 
    
    obterProfessoresListaGrid(): Observable<Professor[]> {
        return this.http.get<Professor[]>(`${BACKEND_API}/professores/obterprofessoreslistagrid`)
    } 

    update(professor: Professor): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/professores`, professor)
        .pipe(map((result: any) => result))
    }

    uploadProfessorFoto(file: any, id:number ): Observable<any>{

        let form = new FormData()

            form.append('file', file)
            form.append('id', id.toString())

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'false',
            "processData": 'false'
          })
        }
        return this.http.post<any>(`${BACKEND_API}/files/uploadprofessorfoto`, form)
        .pipe(map((result: any) => result))
    }

    obterFormadores(): Observable<any[]> {
        return this.http.get<any[]>(`${BACKEND_API}/formadores`)
    }
}