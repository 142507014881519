import { Component, OnInit, ViewChild } from '@angular/core';
import { ForumService } from '../forum.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroupDirective, FormGroup, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
  selector: 'app-respostas',
  templateUrl: './respostas.component.html',
  styleUrls: ['./respostas.component.scss']
})
export class RespostasComponent implements OnInit {

  discussionId: any
  discussion: any
  comments: any =[]
  text: string 
  
  @ViewChild("ckeDescricao") ckeditor: any;
  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  
  constructor(private forumSerivice: ForumService, 
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { 

      this.discussionId = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']
    }

  ngOnInit() {    
    this.cadastroForm = this.fb.group({
      fixo: this.fb.control('', [Validators.required]),
      subject: this.fb.control(''),
      descricao: this.fb.control(''),
      turmaId: this.fb.control('')        
    })
    this.load(this.discussionId)
  }

  load(id){
    this.forumSerivice.getOneDiscussion(id).subscribe(result=>{
      this.discussion = result[0]
      this.comments = result[0].comments_docs
    }, (error)=>{
      console.log(error)
    })
  }

  gravar(text){
    let data: any ={}
    data.text = text
    data.discussionId = this.discussionId
    this.forumSerivice.createComments(data).subscribe(result =>{
      swal({
        title: 'Potencial Gobal',
        text: 'Resposta cadastrado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      })
      this.load(this.discussionId)
      this.text ="s"

    }, (error)=>{
      swal({
        title: 'Potencial Gobal',
        text: 'Resposta não cadastrado!',
        type: 'warning',
        showConfirmButton: false,
        timer: 1500
      })
    })
  }

}
