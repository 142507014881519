import { Component, OnInit, ViewChild, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormGroupDirective } from '@angular/forms';
import { CursoService } from '../curso.service';
import { Curso, Categoria } from '../curso.model';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { MatPaginator, MatTableDataSource, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSortable } from '@angular/material';
import { BACKEND_FILE } from 'src/app/app.api';
import { DialogData } from '../capitulo/formcapitulo/formcapitulo.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';



@Component({
  selector: 'app-formcurso',
  templateUrl: './formcurso.component.html',
  styleUrls: ['./formcurso.component.scss']
})
export class FormcursoComponent implements OnInit {

  name = 'ng2-ckeditor';
  ckeConfig: any;
  log: string = '';
  form: FormGroup;

  @ViewChild("ckeDescricao") ckeditor: any;
  
  displayedColumnsCom = ['ordem', 'nome', 'acao']
  displayedColumnsSem = ['ordem', 'nome', 'acao']
  
  dataSourceSem: MatTableDataSource<any>;
  dataSourceCom: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumnsConcursoCom = ['nome', 'acao']
  displayedColumnsConcursoSem = ['nome', 'acao']
  
  dataConcursoSem: MatTableDataSource<any>;
  dataConcursoCom: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginatorConcurso: MatPaginator;
  @ViewChild(MatSort) sortConcurso: MatSort;

  concursoCom: any[] = []
  modulosCom: any[] = []

  @ViewChild('nome') inputNome:ElementRef;
  @ViewChild(FormGroupDirective) cadastroForm: FormGroup
  curso: Curso[]

  id : number = undefined
  editorValue: any
  descricao: string 
  foto: any
  changedSort: boolean = false

  categorias: Categoria[]

  constructor(private cursoService: CursoService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private changeDetectorRefs: ChangeDetectorRef) { 

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {
        
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.cursoService.obterCategorias().subscribe(result => {
      this.categorias = result

    })

    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      idCategoria: this.fb.control(''),
      valorMensal: this.fb.control(''),
      valorTrimestral: this.fb.control(''),
      valorSemestral: this.fb.control(''),
      valorAnual: this.fb.control(''),
      pequenaDescricao: this.fb.control(''),
      diferenciais: this.fb.control(''),
      status: this.fb.control(''),
      editorValue: this.fb.control(''),
      cargaHoraria:this.fb.control(''),
      referencia:this.fb.control(''),
      pontos:this.fb.control('')
    })

    // this.form = this.fb.group({
    //   modulosCurso: this.fb.array([])
    // });

    if(this.id!=undefined){
      this.load()
    } else {
      this.cadastroForm.patchValue({
        visibilidade: 'E',
        completo: 'S',
        status : true
      })
    }
    this.loadConcurso(0)
    this.loadModulo(0)
  }
  
  public onChange( event ) {
    this.descricao = event
  }
  
  doFilterCom(filterValue:string){
    this.dataSourceCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterSem(filterValue:string){
    this.dataSourceSem.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterConcursoCom(filterValue:string){
    this.dataConcursoCom.filter = filterValue.trim().toLocaleLowerCase()
  }

  doFilterConcursoSem(filterValue:string){
    this.dataConcursoSem.filter = filterValue.trim().toLocaleLowerCase()
  }

  excluir(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.modulosCom.findIndex(result=> result === id)

    this.modulosCom.splice(index,1)

    if(this.modulosCom.length==0){
      this.loadModulo('-1')  
    }else{
      this.loadModulo(this.modulosCom)  
    }
  }

  excluirConcurso(id: number){
        
    swal({
      title: 'Removendo...'
    });
    swal.showLoading();

    let index:number = this.concursoCom.findIndex(result=> result === id)

    this.concursoCom.splice(index,1)

    if(this.concursoCom.length==0){
      this.loadConcurso('-1')
    }else{
      this.loadConcurso(this.concursoCom)
    }
  }

  incluir(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.modulosCom.push(id)
    this.loadModulo(this.modulosCom)
  }
  
  incluirConcurso(id: number){
        
    swal({
      title: 'Incluindo...'
    });
    swal.showLoading();

    this.concursoCom.push(id)
    console.log(this.concursoCom)
    this.loadConcurso(this.concursoCom)
  }

  limpar(formDirective: FormGroupDirective){
    
    this.id=undefined
    this.foto = undefined

    formDirective.resetForm()
    this.cadastroForm.reset()
    
    this.cadastroForm.patchValue({
      visibilidade: 'E',
      completo: 'S',
      status : true
    })
    
    this.modulosCom=[]
    this.descricao = ''
    this.loadConcurso(0)
    this.loadModulo(0)
    this.inputNome.nativeElement.focus()

  }

  load(){
    this.cursoService.obterCursoId(this.id).subscribe(result => {

      this.cadastroForm.patchValue({
        id: result[0].id,
        nome: result[0].nome,
        idCategoria: result[0].idCategoria,
        valorMensal: result[0].valorMensal,
        valorTrimestral: result[0].valorTrimestral,
        valorSemestral: result[0].valorSemestral,
        valorAnual: result[0].valorAnual,
        pequenaDescricao: result[0].pequenaDescricao,
        diferenciais: result[0].diferenciais,
        referencia: result[0].referencia,
        status : result[0].status == "A"? true : false,
        cargaHoraria:result[0].cargaHoraria,
        pontos:result[0].pontos,
      })

      this.descricao = result[0].descricao
    })
    this.retornarFoto()
  }
  
  loadConcurso(com: any){

    this.cursoService.obterConcursosSemCurso(this.id, com).subscribe(result => {
      this.dataConcursoSem = new MatTableDataSource(result);
      this.dataConcursoSem.paginator = this.paginator;
      this.dataConcursoSem.sort = this.sort;
      swal.close()
    })

    this.cursoService.obterConcursoCurso(this.id, com).subscribe(result => {
      let newData = result
      newData.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem));  
      this.dataConcursoCom = new MatTableDataSource(result);
      this.dataConcursoCom.paginator = this.paginator;
      this.dataConcursoSem.data = newData
      if(this.concursoCom.length==0){
        result.forEach(element => {
          this.concursoCom.push(element.id)        
        }); 
      }      
    })
  }

  loadModulo(com: any){
        
    this.cursoService.obterModuloSemCurso(this.id, com).subscribe(result => {
      this.dataSourceSem = new MatTableDataSource(result);
      this.dataSourceSem.paginator = this.paginator;
      this.dataSourceSem.sort = this.sort;
      swal.close()
    })

    this.cursoService.obterModuloCurso(this.id, com).subscribe(result => {
      this.dataSourceCom = new MatTableDataSource(result);
      this.dataSourceCom.paginator = this.paginator;      
      if(this.modulosCom.length==0){
        result.forEach(element => {
          this.modulosCom.push(element.id)        
        }); 
      }      
    })
  }

  moveDown(position,index){
    // this.dataSourceCom
    this.changedSort = true
    let newData: any[] = this.dataSourceCom.data
    newData.forEach(element => {
      if(element.ordem == (position)){
        newData[index].ordem = index +2
        newData[index+1].ordem = index +1       
      }
    });
    
    this.dataSourceCom.data = newData.sort((a, b) => parseFloat(a.ordem) - parseFloat(b.ordem));  
  }

  moveUp(position,index){
    this.changedSort = true
    let newData: any[] = this.dataSourceCom.data
    newData.forEach(element => {
      if(element.ordem == (position)){
        newData[index-1].ordem = index + 1
        newData[index].ordem = index 
      }
    });
    this.dataSourceCom.data = newData.sort((a, b) => a.ordem - b.ordem)
  }

  getLengthRows()
  {
    return this.dataSourceCom.data.length
  }  

  gravar(curso : Curso, descricaoCurso: any){
    
    curso.descricao = descricaoCurso

    if(!curso.valorMensal)
      curso.valorMensal = 0

    if(!curso.valorTrimestral)
      curso.valorTrimestral = 0

    if(!curso.valorSemestral)
      curso.valorSemestral = 0

    if(!curso.valorAnual)
      curso.valorAnual = 0

    if(this.id == undefined){
      this.save(curso)
    }else{
      this.update(curso)
    }
  }

  saveSort(){
    this.changedSort = false

    this.cursoService.updateSortModulo(this.dataSourceCom.data).subscribe(result=>{
      swal({
          title: 'Potencial Global',
          text: 'Ordenação alterado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })

        // setTimeout( () => {
        //     this.router.navigate(['/dashboard/cursos'])
        // }, 2000 );
       
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro da ordenação dos modulos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })

    console.log(this.dataSourceCom.data)

  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(UploadCursoFoto, {
      width: '400px',
      height: '600px',
      data:{ id : this.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.foto = undefined
      setTimeout( () => {
        this.retornarFoto()
      }, 3000 );
    });
  }

  retornarFoto(){
    this.cursoService.obterCursoFoto().subscribe(result=>{
      if(result){
        result.forEach(element => {
          let valida: string = element
          if(this.id.toString() == valida.substring(0,this.id.toString().length)){
            this.foto = BACKEND_FILE + "/foto/curso/" + element
          }
        });
      }
    })
  }

  save(curso : Curso){

    this.cursoService.createCurso(curso).subscribe(result=>{

      this.id = result.id
      
      this.cursoService.createCursoModulo(result.id, this.modulosCom).subscribe(result=>{

        this.cursoService.createConcursoCurso(result.id, this.concursoCom).subscribe(result=>{
          swal({
            title: 'Potencial Global',
            text: 'Curso cadastrado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        })
      })
    },(error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Ocorreu um erro no cadastro de cursos.',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
    })
  }

  update(curso: Curso) {

    curso.status = curso.status.toString() == 'true' ? 'A' : 'D'
    curso.id = this.id

    this.cursoService.updateCurso(curso).subscribe(result=>{
      this.cursoService.createCursoModulo(this.id, this.modulosCom).subscribe(result=>{
        this.cursoService.createConcursoCurso(this.id, this.concursoCom).subscribe(result=>{
          swal({
            title: 'Potencial Global',
            text: 'Curso alterado com sucesso!',
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          setTimeout( () => {
              this.router.navigate(['/dashboard/cursos'])
            }, 2000 );
          })
        })
      },(error: HttpErrorResponse)=>{
    })
  }
}

@Component({
  selector: 'uploadcursofoto',
  templateUrl: './uploadcursofoto.html',
})

export class UploadCursoFoto {
  imageChangedEvent: any = '';
  croppedImage: any = ''

  constructor(private cursoService: CursoService,
              public dialogRef: MatDialogRef<UploadCursoFoto>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  upload(){

    this.cursoService.uploadCursoFoto(this.croppedImage, this.data.id).subscribe(result=>{

    },(error)=>{
      console.log('error=>', error)
    })
    this.onNoClick()
  }
}