import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Acao } from "./acao.model";

@Injectable()

export class AcaoService{

    constructor(private http: HttpClient){}
    
    createAcao(dados: any): Observable<any>{        

        return this.http.post<any>(`${BACKEND_API}/acao`, dados)
        .pipe(map((result: any) => result))
    }

    deleteacao(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/acao/${id}/delete`)
        .pipe(map((result: any) => result))
    }

    obterAcao(): Observable<Acao[]> {
        return this.http.get<Acao[]>(`${BACKEND_API}/acao`)
    }

    

    obterAcaoId(id): Observable<any> {
        return this.http.get<any>(`${BACKEND_API}/acao/${id}`)
    }

    updateAcao(acao: any): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/acao/${acao.id}`, acao)
        .pipe(map((result: any) => result))

    }

}