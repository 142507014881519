import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import swal from 'sweetalert2';
import { RelatorioService } from '../relatorio.service';
import { RelatorioUtil } from '../../shared/util/export-excel.util'
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { LoginService } from 'src/app/security/login/login.service';

@Component({
  selector: 'app-atividades',
  templateUrl: './atividades.component.html',
  styleUrls: ['./atividades.component.scss']
})
export class AtividadesComponent implements OnInit {

  displayedColumns = ['nome', 'descricao','statusCurso']
  dataSource: MatTableDataSource<any>;
  turmaId: any
  empresas: any
  empresa: number
  acoes: any
  role: number

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableExport') tableExport: ElementRef;

  constructor(private relatorioService: RelatorioService, 
    private empresaService: EmpresaService, private loginService: LoginService) { }

  ngOnInit() {
    this.load()

  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }

  load(){
    this.role = this.loginService.getRole()
    this.getEmpresas()

    if(this.role == 3){
      this.retornaAcao(1) //empresa apenas para constar, é selecionado a empresa correta no backend
    }
  }

  getTimeString(time){
    if(time.length > 0){
      let date = new Date(0)
      date.setMinutes(time[0].timer)
      return date.toISOString().substr(11,8)
    }else{
      return 0
    }
  }

  getTimeStringRealTime(time){
    if(time.length > 0){
      let date = new Date(0)
      date.setMinutes(time[0].realTime)
      return date.toISOString().substr(11,8)
    }else{
      return 0
    }
  }

  exportTable(){
    RelatorioUtil.exportToExcel("tableExporter");
  }

  getTurma(turma){
    
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();

    this.relatorioService.obterformandosatividadeStatus(turma).subscribe(retorno => {
      this.dataSource = new MatTableDataSource(retorno);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    }, (error)=>{
      swal({
        title: 'Potencial Global',
        text: 'Não contem horas de atividades para essa turma',
        type: 'warning',
        showConfirmButton: false,
        timer: 2000
      })
      swal.close()
    })

  }

  getEmpresas(){
    this.empresaService.obterEmpresas()
          .subscribe(retorno => {
          this.empresas = retorno
        })
  }

  retornaAcao(empresa){
    this.relatorioService.obterAcaoEmpresa(empresa).subscribe(result=>{
      this.acoes = result
    }, (error)=>{
      console.log(error)
    })
  }

  retornaAcaoRole(empresa){
    this.relatorioService.obterAcaoEmpresa(empresa).subscribe(result=>{
      this.acoes = result
    }, (error)=>{
      console.log(error)
    })
  }

  hideColunmRole(): string{
    return this.role === 1 ? null : 'hidden-row';
  }

}
