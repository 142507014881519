import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AcaoService } from './acao.service';
import { Acao } from './acao.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-acao',
  templateUrl: './acao.component.html',
  styleUrls: ['./acao.component.scss']
})
export class AcaoComponent implements OnInit {


  displayedColumns = ['acaoNome', 'turma', 'curso', 'acao']
  dataSource: MatTableDataSource<Acao>
  empresaId: any
  
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  constructor(private acaoService: AcaoService,
    private router: Router) { }

  ngOnInit() {
    this.load()
  }

  load(){
    this.acaoService.obterAcao().subscribe(result =>{
      this.dataSource =  new MatTableDataSource(result); 
    })
  }

  update(id: number){
    this.router.navigate(['dashboard/acao/form/edit',id]);
  }

  doFilter(filterValue:string){
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase()
  }


}
