import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { RelatorioService } from '../relatorio.service';
import { EmpresaService } from 'src/app/empresas/empresa.service';
import { RelatorioUtil } from 'src/app/shared/util/export-excel.util';
import swal from 'sweetalert2';

@Component({
  selector: 'app-relatorio-analitico-cursos',
  templateUrl: './relatorio-analitico-cursos.component.html',
  styleUrls: ['./relatorio-analitico-cursos.component.scss']
})
export class RelatorioAnaliticoCursosComponent implements OnInit {


  displayedColumns = ['nome', 'nif', 'pontos']
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableExport') tableExport: ElementRef;

  constructor(private empresaService: EmpresaService,
            private relatorioService: RelatorioService) { }

  ngOnInit() {
    this.obtEprempresas()
  }

  empresas: any
  empresaId: any
  cursoId: any
  cursos: any

  obtEprempresas(){
    this.empresaService.obterEmpresas()
          .subscribe(retorno => {
          this.empresas = retorno
        })
  }


  retornaCursos(){
    this.empresaService.obterCursoEmpresas(this.empresaId).subscribe(result=>{
      this.cursos = result
    }, (error)=>{
      console.log(error)
    })
  }

  retornaFormandosConcluidos(){
    swal({
      title: 'Carregando dados'
    });
    swal.showLoading();
    this.relatorioService.obterformandosConcluidos(this.empresaId, this.cursoId).subscribe(result=>{
      this.dataSource = new MatTableDataSource(result.formandos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      swal.close()
    }, (erro)=>{
      
      swal.close()
    })
  }

  exportTable(){
    RelatorioUtil.exportToExcel("tableExporter");
  }

}
