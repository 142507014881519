import { Component, OnInit, ViewChild } from '@angular/core';
import { Usuario } from '../usuario.model';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { UsuarioService } from '../usuario.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { EmpresaService } from 'src/app/empresas/empresa.service';

@Component({
  selector: 'app-formusuario',
  templateUrl: './formusuario.component.html',
  styleUrls: ['./formusuario.component.scss']
})
export class FormusuarioComponent implements OnInit {

  @ViewChild(FormGroupDirective) cadastroForm : FormGroup
  
  usuario : Usuario[]
  empresas: any

  id : number = undefined

  constructor(private usuarioService: UsuarioService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private empresaService: EmpresaService) {

                this.id = this.activatedRoute.snapshot.params['id']==undefined ? undefined : this.activatedRoute.snapshot.params['id']

              }

  ngOnInit() {

    this.cadastroForm = this.fb.group({
      id: this.fb.control(''),
      nome: this.fb.control('', [Validators.required]),
      username: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required]),
      sexo: this.fb.control('',[Validators.required]),
      status: this.fb.control(''),
      roleId: this.fb.control(''),
      empresa: this.fb.control('')
    })

    this.getUsuario(this.id)
    this.getEmpresa()

  }

  getUsuario(id: number){

    if(id) {
    
      this.usuarioService.obterUsuarioId(id).subscribe(result => {
        console.log(result[0].status == "A"? true : false)
        this.cadastroForm.patchValue({
          nome : result[0].nome,
          username : result[0].username,
          email : result[0].email,
          sexo : result[0].sexo,
          status : result[0].status == "A"? true : false,
          roleId: result[0].roleId,
          empresa: result[0].idEscola
        })
      })
    }
  }

  gravar(usuario : Usuario, formDirective: FormGroupDirective){
    this.usuarioService.obterUsuarioUsername(usuario.username).subscribe(result => {
      let existe = result[0]==undefined ? "N" : result[0].id

      if(this.id==undefined){

        if(existe=="N"){
          this.save(usuario, formDirective)
        } else {
          swal({
            title: 'Potencial Global',
            text: 'Username já utilizado!',
            type: 'error',
            showConfirmButton: false,
            timer: 2000
          })
        }

      }else{

        if(existe=="N" || (existe!="N"  && Number(existe)== Number(this.id))){
          this.update(usuario)
        } else {
          swal({
            title: 'Potencial Global',
            text: 'Username já utilizado!',
            type: 'error',
            showConfirmButton: false,
            timer: 2000
          })
        }

      }
    })
  }

  save(usuario : Usuario, formDirective: FormGroupDirective){

      this.usuarioService.create(usuario).subscribe(result=>{
        swal({
          title: 'Potencial Global',
          text: 'Usuário cadastrado com sucesso!',
          type: 'success',
          showConfirmButton: false,
          timer: 1500
        })
        formDirective.resetForm();
        this.cadastroForm.reset();

      },(error)=>{
        swal({
          title: 'Potencial Global',
          text: 'Ocorreu um erro no cadastro de usuário.',
          type: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
      })

  }

  update(usuario: Usuario) {

    usuario.status = usuario.status.toString() == 'true' ? 'A' : 'D'
    usuario.id = this.id
    this.usuarioService.update(usuario).subscribe(result=>{
      swal({
        title: 'Potencial Global',
        text: 'Usuário alterado com sucesso!',
        type: 'success',
        showConfirmButton: false,
        timer: 2000
      })

      setTimeout( () => {
          this.router.navigate(['/dashboard/usuarios'])
        }, 1000 );
      },(error: HttpErrorResponse)=>{
    })

  }

  getEmpresa(){

    this.empresaService.obterEmpresas().subscribe(result =>{
      this.empresas = result
    })

  }
}