import * as XLSX from "xlsx";

export class RelatorioUtil {
  static exportToExcel(table: any) {
    let timeSpan = new Date().toISOString();
    let prefix = name || "PoencialGlobal";
    let fileName = `${prefix}-${timeSpan}`;
    let targetTableElm = document.getElementById(table);
    let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: prefix });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}