import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BACKEND_API } from "../app.api";
import { Usuario } from "./usuario.model";


@Injectable()

export class UsuarioService{

    private roleId : number
    constructor(private http: HttpClient){}
    
    create(usuario: Usuario): Observable<any>{
        return this.http.post<any>(`${BACKEND_API}/usuarios`, usuario)
        .pipe(map((result: any) => result))
    }
        
    delete(id: number): Observable<any>{
        return this.http.delete<any>(`${BACKEND_API}/usuarios/${id}`)
        .pipe(map((result: any) => result))
    }

    obterUsuarioId(id : number): Observable<Usuario> {
        return this.http.get<Usuario>(`${BACKEND_API}/usuarios/obterusuarioid/${id}`)
    }  
   
    obterUsuarioUsername(username : string): Observable<Usuario> {
        return this.http.get<Usuario>(`${BACKEND_API}/usuarios/obterusuariousername/${username}`)
    }  
    
    obterUsuariosListaGrid(): Observable<Usuario[]> {
        return this.http.get<Usuario[]>(`${BACKEND_API}/usuarios/obterusuarioslistagrid`)
    } 

    update(usuario: Usuario): Observable<any>{
        return this.http.put<any>(`${BACKEND_API}/usuarios`, usuario)
        .pipe(map((result: any) => result))
    }

    
}